<script>
import { mapGetters } from "vuex";
import VOpenDaysDetail from "@/views/Reports/components/VOpenDaysDetail";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import VFilters from "@/components/Search/VFilters";
import TaskService from "@/services/TaskService";
import MessagesMixin from "@/mixins/Messages";

export default {
    name: "VOpenDays",
    mixins: [DateRangeMixin, MessagesMixin],
    components: {
        VFilters,
        DateRangePicker,
        VOpenDaysDetail,
    },
    data() {
        return {
            loading: true,
            days: [],
            showModal: false,
            modalContext: {},
        };
    },
    async created() {
        this.resetSearch();
        this.$apiEnableWatcher();
        await this.fetchData();
    },

    methods: {
        openSourceModal(user) {
            this.modalContext = {
                user,
                dateRange: this.serializeDateRange(this.dateRange),
            };

            this.showModal = true;
        },
        /**
         * Fetch salary data
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                this.selectedTravels = [];
                const { data } = await TaskService.GET_openDaysReport(params);
                this.days = data.data;
            } catch (e) {
                console.log("f e", e);
            }

            this.loading = false;
        },
        async resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            };
        },
    },
};
</script>
<template>
    <div class="v-travels-index">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- V-FILTERS -->
                        <div class="px-4 mb-4">
                            <v-filters
                                :search="false"
                                :start-open="true"
                                :enable-filters="true"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-3">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="apiSearchFilter.dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange(
                                                        'apiSearchFilter',
                                                        'dateRange'
                                                    )
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                </div>
                            </v-filters>
                        </div>

                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="days.length > 0">
                            <v-table class="table-mg table table-sm">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("open_days.tbl_user")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("open_days.tbl_email")
                                        }}</v-th>

                                        <v-th class="text-right">{{
                                            $t("open_days.tbl_open_days")
                                        }}</v-th>

                                        <v-th class="text-right">{{
                                            $t("salary.report.tbl_closed_days")
                                        }}</v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr
                                        v-for="(d, index) in days"
                                        :key="d.user_id"
                                    >
                                        <v-td>
                                            {{ d.user.name }}
                                        </v-td>
                                        <v-td>
                                            {{ d.user.email }}
                                        </v-td>
                                        <v-td class="text-right">
                                            {{ d.total_open }}
                                            <div
                                                class="small text-muted m-click"
                                                v-if="
                                                    parseInt(d.total_open) > 0
                                                "
                                                @click="openSourceModal(d.user)"
                                            >
                                                {{
                                                    $t("open_days.show_details")
                                                }}
                                            </div>
                                        </v-td>
                                        <v-td class="text-right">
                                            {{ d.total_closed }}
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>

                            <div class="text-right mt-4 px-4">
                                <hr />
                                <base-button type="primary">{{
                                    $t("salary.export_to_csv_and_mark")
                                }}</base-button>
                            </div>
                        </template>
                        <template v-else>
                            <div class="px-4">
                                <div class="alert alert-warning">
                                    {{ $t("travels.no_data_found") }}
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-open-days-detail
                v-if="showModal"
                :context="modalContext"
                @close="showModal = false"
            />
        </portal>
    </div>
</template>

<style lang="scss">
</style>
