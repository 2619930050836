<script>
import TaskService from "@/services/TaskService";

export default {
    name: "VOpenDaysDetail",
    props: {
        context: {},
    },
    data() {
        return {
            loading: true,
            tasks: [],
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await TaskService.GET({
                    user_id: this.context.user.id,
                    dateRange: this.serializeDateRange(this.context.dateRange),
                    is_closed: 0,
                    force_all: 1,
                    force_sort_date: 1
                });

                this.tasks = data.data;
            } catch (e) {}

            this.loading = false;
        },
    },
};
</script>

<template>
    <modal
        size="xxl"
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="asset-modal"
    >
        <template slot="header">{{
            $t("open_days.open_days_details_for_#USER", {
                USER: context.user.name,
            })
        }}</template>
        <template slot="default">
            <v-loader :loading="loading" v-if="loading" />
            <template v-else-if="tasks.length > 0">
                <v-table class="table table-sm table-striped">
                    <v-thead>
                        <v-tr>
                            <v-th>{{$t('open_days_detail.date')}}</v-th>
                            <v-th>{{$t('open_days_detail.project_customer')}}</v-th>
                            <v-th>{{$t('open_days_detail.activity')}}</v-th>
                            <v-th>{{$t('open_days_detail.details')}}</v-th>
                            <v-th class="text-right">{{$t('open_days_detail.used_time')}}</v-th>
                        </v-tr>
                    </v-thead>

                    <v-tbody>
                        <v-tr v-for="task in tasks" :key="task.id">
                            <v-td>{{
                                $d(new Date(task.task_date), "short")
                            }}</v-td>
                            <v-td>
                                <template v-if="'project' in task">
                                    {{ task.project.title }}
                                    <span class="text-muted"
                                        >(#{{ task.project.id }})</span
                                    >
                                    <div class="small text-muted">
                                        {{ task.customer.name }}
                                    </div>
                                </template>
                                <template v-else>
                                    {{
                                        task.customer.internal_customer_alt_name
                                    }}
                                    <div class="small text-muted">
                                        {{ task.customer.name }}
                                    </div>
                                </template>
                            </v-td>
                            <v-td>{{ task.activity.name }}</v-td>
                            <v-td>
                                <v-empty :text="task.details" />
                            </v-td>
                            <v-td class="text-right">
                                {{ task.time_reg |toTime }}
                            </v-td>
                        </v-tr>
                    </v-tbody>
                </v-table>
            </template>
        </template>
    </modal>
</template>


<style>
</style>
